import React from "react";
import "./App.css";
import firebase from "firebase";

class App extends React.Component {
  state = {
    email: "",
    disabled: false
  };

  handleChange(event) {
    this.setState({ email: event.target.value });
  }

  onsubmit = e => {
    const firebaseConfig = {
      apiKey: "AIzaSyDkXJzfToAQIEiSj-meS_8hAMQZqxXFsqA",
      authDomain: "friendlyfounders-9d213.firebaseapp.com",
      databaseURL: "https://friendlyfounders-9d213.firebaseio.com",
      projectId: "friendlyfounders-9d213",
      storageBucket: "friendlyfounders-9d213.appspot.com",
      messagingSenderId: "681603353213",
      appId: "1:681603353213:web:23eaaef54cc6a0b3bf3f14",
      measurementId: "G-KB3PFE1GY7"
    };
    firebase.initializeApp(firebaseConfig);
    e.preventDefault();
    if (!this.state.disabled) {
      const db = firebase.firestore();
      const userRef = db.collection("emails").add({
        email: this.state.email
      });
    }
    this.setState({ email: "",disabled: true });
  };

  render() {
    return (
      <div className="centered">
        <div className="row">
          <div className="col align-self-center"></div>
          <div className="col-30">
            <div className="card card-block">
              <h3>Interested in friends and family funding?</h3>
              <form>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email address</label>
                  <input
                    value={this.state.email}
                    onChange={e => this.handleChange(e)}
                    name="email"
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ width: "100%" }}
                  onClick={this.onsubmit}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
          <div className="col"></div>
        </div>
      </div>
    );
  }
}

export default App;
